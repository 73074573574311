<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1 d-flex">
      <span>Muokkaa hyvityslaskua</span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="goBack()"> Takaisin </v-btn>
    </div>

    <invoice-form
      :contr="contract"
      :inv="invoice"
      :refundedInv="refundedInvoice"
      save-btn-text="Tallenna luonnos"
      @sendrefundinvoice="submit($event)"
    ></invoice-form>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import InvoiceForm from "../../components/Invoice/InvoiceForm";
import { mapMutations } from "vuex";

export default {
  title: "Muokkaa hyvityslaskua",

  components: {
    InvoiceForm,
  },

  mixins: [mixins],

  data() {
    return {
      invoice: {
        otherDueDates: {},
        products: [],
        sendType: "post",
        freeText:
          "Vuokranmaksupäivä on joka kuukauden viides päivä. Maksa vuokra aina samalla viitteellä.",
        outgoingPayments: { inUse: false },
      },
      refundedInvoice: { outgoingPayments: { inUse: false } },
      contract: {},
    };
  },

  async created() {
    try {
      this.setLoading(true);

      let res = await axiosMethods.get(
        `/api/v1/invoices/${this.$route.params.id}?getRefundedInvoice=true`
      );
      // Set refund Invoice
      this.refundedInvoice = res.data.refundedInvoice;

      // Set invoice
      let invoice = res.data.invoice;
      this.contract = invoice.contract;

      let inv = {
        billDate: new Date(invoice.billDate).toISOString().substring(0, 10),
        accountDate: new Date(invoice.accountDate).toISOString().substring(0, 10),
        dueDate: new Date(invoice.dueDate).toISOString().substring(0, 10),
        freeText: invoice.freeText,
        sendType: invoice.sendType,
        tenant: invoice.tenant,
        evoice: invoice.evoice,
        products: invoice.products,
        sendToApartmentAddress: invoice.sendToApartmentAddress,
        sendingAddress: invoice.sendingAddress
          ? invoice.sendingAddress
          : { address: null, zipCode: null, city: null, country: null },
        outgoingPayments: invoice.outgoingPayments,
        contract: invoice.contract,
        yourCode: invoice.yourCode,
        language: invoice.language ? invoice.language : "fin",
        ropoCapital: invoice.ropoCapital,
        talenom: invoice.talenom,
        costCentre: invoice.costCentre,
      };

      this.invoice = inv;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    ...mapMutations("invoice", ["setLoading", "setSaving"]),

    async submit(data) {
      try {
        await axiosMethods.update(
          `/api/v1/invoices/refund-related/edit/${this.$route.params.id}`,
          data
        );
        this.setSaving(false);

        this.$router.push("/refund-invoices");
        this.showPopup("Laskua muokattu", "success");
      } catch (err) {
        this.setSaving(false);
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style scoped></style>
